import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectPlace } from '../actions/room';

class Place extends Component {
    constructor (props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick () {
        if (this.props.bookable && window.participantId) {
            this.props.onPlaceClick(this.props.place.id);
        }
    }

    render () {
        let backgroundColor, color;
        if (!this.props.bookable) {
            backgroundColor = 'grey';
            color = 'white';
        } else if (this.props.reserved === this.props.place.id) {
            backgroundColor = 'green';
            color = 'white';
        } else if (this.props.selected === this.props.place.id) {
            backgroundColor = 'blue';
            color = 'white';
        } else if (!this.props.place.available) {
            backgroundColor = 'red';
            color = 'white';
        } else {
            backgroundColor = 'white';
            color = 'black';
        }

        let cursor = 'not-allowed'
        if (!window.participantId) {
            cursor = 'default';
        } else if (this.props.bookable) {
            cursor = 'pointer';
        }

        let placeText = this.props.bookable
            ? this.props.place.name
            : null;

        return (
            <div
                style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: backgroundColor,
                    color: color,
                    border: '1px solid black',
                    gridColumn: this.props.column,
                    gridRow: this.props.row,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: cursor
                }}
                onClick={this.handleClick}
            >
                <span>
                    {placeText}
                </span>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        selected: state.room.selected,
        reserved: state.room.reserved
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onPlaceClick: id => {
            dispatch(selectPlace(id));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Place);

import { call, put, takeLatest } from 'redux-saga/effects';
import Axios from 'axios';

function* fetchEvent (action) {
    try {
        const event = yield call(Axios.get, action.apiUrl + 'event/' + action.eventId);

        // yield put({ type: 'EVENT_FETCH_SUCCEEDED', message: e.message });
        yield put({ type: 'SET_RESERVED_PLACES', places: event.data.booked_places });
    } catch (e) {
        yield put({ type: 'EVENT_FETCH_FAILED', message: e.message });
    }
}

function* fetchParticipant (action) {
    try {
        const participant = yield call(
            Axios.get,
            action.apiUrl + 'event/' + action.eventId + '/' + action.participantId
        );

        yield put({ type: 'SET_PLACE_RESERVATION', place: participant.data.booked_place });
    } catch (e) {
        yield put({ type: 'PARTICIPANT_FETCH_FAILED', message: e.message });
    }
}

function* initSaga () {
    yield takeLatest('EVENT_FETCH_REQUESTED', fetchEvent);
    yield takeLatest('PARTICIPANT_FETCH_REQUESTED', fetchParticipant);
}

export default initSaga;

export function createRoom (room) {
    return {
        type: 'CREATE_ROOM',
        room
    };
}

export function setPlaceReservation (place) {
    return {
        type: 'SET_PLACE_RESERVATION',
        place
    };
}

export function selectPlace (id) {
    return {
        type: 'SELECT_PLACE',
        id
    };
}

export function bookPlace (apiUrl, eventId, participantId, place) {
    return {
        type: 'PLACE_RESERVATION_REQUESTED',
        apiUrl,
        eventId,
        participantId,
        place
    };
}

export function fetchEvent (apiUrl, eventId) {
    return {
        type: 'EVENT_FETCH_REQUESTED',
        apiUrl,
        eventId
    };
}

export function fetchParticipant (apiUrl, eventId, participantId) {
    return {
        type: 'PARTICIPANT_FETCH_REQUESTED',
        apiUrl,
        eventId,
        participantId
    };
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Place from './Place';

class Table extends Component {
    render () {
        const places = this.props.table.places.map((placeId, index) => {
            const column = Math.ceil((index + 1) / this.props.table.height);
            const row = (index % this.props.table.height) + 1;

            return <Place key={placeId} place={this.props.places[placeId]} column={column} row={row}
                          bookable={this.props.table.bookable} />;
        });

        return (
            <div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(' + this.props.table.width + ', auto)' }}>
                    {places}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        places: state.room.places
    };
};

export default connect(
    mapStateToProps
)(Table);

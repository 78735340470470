import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '../node_modules/@blueprintjs/core/lib/css/blueprint.css';
import '../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css';
import { applyMiddleware, createStore, compose } from 'redux';
import reducer from './reducer';
import { Provider } from 'react-redux';
import {createRoom, fetchEvent, fetchParticipant} from './actions/room';
import createSagaMiddleware from 'redux-saga';
import initSaga from './sagas/init';
import bookSaga from './sagas/book';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(initSaga);
sagaMiddleware.run(bookSaga);

store.subscribe(() => {
    console.log(store.getState());
});

store.dispatch(createRoom([
    [
        {
            name: 'Admin',
            width: 6,
            height: 1,
            bookable: false
        },
        {
            name: 'Stage',
            width: 2,
            height: 2,
            bookable: false
        },
        {
            name: 'Admin',
            width: 6,
            height: 1,
            bookable: false
        }
    ],
    [
        {
            name: 'A',
            width: 2,
            height: 13
        },
        {
            name: 'B',
            width: 2,
            height: 13
        },
        {
            name: 'C',
            width: 2,
            height: 13,
            bookable: false
        },
        {
            name: 'D',
            width: 2,
            height: 13
        },
        {
            name: 'E',
            width: 2,
            height: 13
        }
    ]
]));

if (window.eventId) {
    store.dispatch(fetchEvent(window.apiUrl, window.eventId));

    if (window.participantId) {
        store.dispatch(fetchParticipant(window.apiUrl, window.eventId, window.participantId));
    }
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

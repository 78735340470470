import { call, put, takeLatest } from 'redux-saga/effects';
import Axios from 'axios';

function* reservePlace (action) {
    try {
        const user = yield call(
            Axios.post,
            action.apiUrl + 'event/' + action.eventId + '/' + action.participantId,
            { place: action.place }
        );

        yield put({ type: 'SET_PLACE_RESERVATION', place: user.data.place });
    } catch (e) {
        yield put({ type: 'PLACE_RESERVATION_FAILED', message: e.message });
    }
}

function* bookSaga () {
    yield takeLatest('PLACE_RESERVATION_REQUESTED', reservePlace);
}

export default bookSaga;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from '@blueprintjs/core';
import { bookPlace } from './actions/room';

class App extends Component {
    constructor (props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick () {
        this.props.onBookClick(this.props.selectedPlace.name);
    }

    render () {
        let bookButtonText;
        if (this.props.selectedPlace) {
            bookButtonText = this.props.reservedPlace
                ? 'Change to'
                : 'Book';

            bookButtonText = bookButtonText + ' ' + this.props.selectedPlace.name;
        } else if (this.props.reservedPlace) {
            bookButtonText = this.props.reservedPlace.name + ' booked';
        } else {
            bookButtonText = 'Select place to book';
        }

        return (
            <Button disabled={!this.props.selectedPlace} onClick={this.handleClick}>
                {bookButtonText}
            </Button>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedPlace: state.room.selected
            ? state.room.places[state.room.selected]
            : null,
        reservedPlace: state.room.reserved
            ? state.room.places[state.room.reserved]
            : null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onBookClick: (place) => {
            dispatch(bookPlace(window.apiUrl, window.eventId, window.participantId, place));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

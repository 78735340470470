import React, { Component } from 'react';
import { connect } from 'react-redux';
import Row from './components/Row';
import BookButton from './BookButton';

class App extends Component {
    render () {
        const rows = this.props.rows.map(row => {
            return <Row key={row.id} row={row} />;
        });

        let bookButton;
        if (window.participantId) {
            bookButton = (
                <div style={{ display: 'grid', justifyContent: 'center', margin: '10px 0' }}>
                    <BookButton />
                </div>
            )
        }

        return (
            <div style={{maxWidth: '1000px', margin: '0 auto'}}>
                {rows}
                {bookButton}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        rows: state.room.rows
    };
};
export default connect(
    mapStateToProps
)(App);

function room (state, action) {
    state = state || {
        tables: [],
        places: [],
        rows: [],
        selected: null,
        reserved: null
    };

    switch (action.type) {
        case 'CREATE_ROOM': {
            let tables = [];
            let places = [];
            let rows = [];
            let tableIndex = 0;
            let placeIndex = 0;

            action.room.forEach((row, rowIndex) => {
                let rowTables = [];

                row.forEach(table => {
                    const placeCount = table.height * table.width;
                    let tablePlaces = [];

                    const bookable = typeof table.bookable === 'undefined'
                        ? true
                        : table.bookable;

                    for (let i = 1; i <= placeCount; i++) {
                        places.push({
                            id: placeIndex,
                            name: table.name + i,
                            table: tableIndex,
                            available: bookable
                        });

                        tablePlaces.push(placeIndex);

                        placeIndex++;
                    }

                    tables.push({
                        id: tableIndex,
                        name: table.name,
                        width: table.width,
                        height: table.height,
                        row: rowIndex,
                        places: tablePlaces,
                        bookable: bookable
                    });

                    rowTables.push(tableIndex);

                    tableIndex++;
                });

                rows.push({
                    id: rowIndex,
                    tables: rowTables
                });
            });

            return {
                ...state,
                tables,
                places,
                rows
            };
        }
        case 'SET_RESERVED_PLACES': {
            let places = state.places.map(place => {
                if (!place.available) {
                    return place;
                }

                return {
                    ...place,
                    available: action.places.indexOf(place.name) === -1
                };
            });

            return {
                ...state,
                places
            };
        }
        case 'SET_PLACE_RESERVATION': {
            let reservedPlaceId = null;
            let places = state.places.slice().map(place => {
                let available = place.available;

                if (place.id === state.reserved) {
                    available = true;
                }

                if (place.name === action.place) {
                    reservedPlaceId = place.id;
                    available = false;
                }

                return {
                    ...place,
                    available
                };
            });

            return {
                ...state,
                places: places,
                reserved: reservedPlaceId,
                selected: null
            };
        }
        case 'SELECT_PLACE': {
            let selected;

            if (action.id === state.reserved) {
                selected = null;
            } else if (!state.places[action.id].available) {
                selected = state.selected;
            } else {
                selected = action.id;
            }

            return {
                ...state,
                selected
            };
        }
        case 'PLACE_RESERVATION_SUCCEEDED':
            return {
                ...state,
                selected: null,
                reserved: action.id
            };
        default:
            return state;
    }
}

export default room;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from './Table';

class Row extends Component {
    render () {
        const tables = this.props.row.tables.map(tableId => {
            return <Table key={tableId} table={this.props.tables[tableId]} />;
        });

        return (
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(' + tables.length + ', auto)',
                placeItems: 'center',
                margin: '10px 0'
            }}>
                {tables}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tables: state.room.tables
    };
};

export default connect(
    mapStateToProps
)(Row);
